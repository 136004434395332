function Header() {
  return (
    <header>
      <video src="/video.mp4" loop autoPlay muted></video>
      <h1>GrandsonTech.Support</h1>
      <h2>Site Under Construction. May not function as desired, thank you for your patience.</h2>
      <div className="row">
        <button className="btn" style={{ cursor: "pointer" }}>
          Button title 1
        </button>

        <button className="btn" style={{ cursor: "pointer" }}>
          Button Title 2
        </button>
      </div>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
